
import { API_URL } from '@/constants/';
import axios from './axios';

export default {
  get({ current, pageSize, ...data }) {
    return axios.get(`${API_URL}/comments`, {
      params: { page: current, pagesize: pageSize, ...data },
    }).then((res) => ({
      data: res.data, total: res.headers['x-total'],
    }));
  },
  add(data) {
    return axios.post(`${API_URL}/comments`, data)
      .then((res) => res.data);
  },
  delete(id) {
    return axios.delete(`${API_URL}/comments/${id}`)
      .then((res) => res.data);
  },
  put({ id, ...data }) {
    return axios.put(`${API_URL}/comments/${id}`, data)
      .then((res) => res.data);
  },
  getDetail(id) {
    return axios.get(`${API_URL}/comments/${id}`)
      .then((res) => res.data);
  },
  question() {
    return axios.get(`${API_URL}/comments/question`).then((res) => (res.data));
  },
  show(data) {
    return axios.get(`${API_URL}/comments/show`, {
      params: data,
    }).then((res) => (res.data));
  },
  change(id) {
    return axios.put(`${API_URL}/comments/videoStatus`, { videoId: id })
      .then((res) => (res.data));
  },
  complete(data) {
    return axios.get(`${API_URL}/comments/complete`, {
      params: data,
    }).then((res) => (res.data));
  },
  export(data) {
    return axios.get(`${API_URL}/comments/export`, {
      params: data,
    }).then((res) => (res.data));
  },
};
